:root {
  --primary-color: #253571;
  --background-color: #f0f0f0;
  --btn: #B49F8B;
  --font-family: "Montserrat", sans-serif;
  --gray-color: #64676e;
}

.color-primary {
  color: var(--primary-color) !important;
}

.bg-custom {
  background-color: var(--primary-color) !important;
}

.bg-custom-off {

  background-color: #D9D9D9 !important;
}

body {
  font-family: var(--font-family);
}

.client-logo-slider {
  margin-top: 20px;
}

.client-logo-slider .swiper-slide div {
  transition: transform 0.3s ease-in-out;
}

.client-logo-slider .swiper-slide div:hover {
  transform: scale(1.03);
}

/* new css starts */

.link-hover:hover {
  text-decoration: underline !important;
}

.custom-btn {
  background-color: var(--btn);
  padding: 13px 40px;
  font-weight: 600;
}

/* Main Wrapper */
.cookie-modal-wrapper {
  position: fixed;
  bottom: 10px;
  left: 10px;
  width: calc(100% - 20px);
  max-width: 400px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  padding: 20px 25px;
  border-radius: 12px;
  transform: translateX(-150%);
  transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
  z-index: 9999999999!important;
  opacity: 0;
}

/* When Modal Slides In */
.cookie-modal-wrapper.slide-in {
  
  transform: translateX(0);
  opacity: 1;
}

/* When Modal Slides Out */
.cookie-modal-wrapper.slide-out {
  transform: translateX(-150%);
  opacity: 0;
}

/* Modal Content Styling */
.cookie-modal-content {
  text-align: center;
}

/* Title Styling */
.cookie-modal-content h5 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 15px;
  color: #333333;
}

/* Text Styling */
.cookie-modal-content p {
  font-size: 0.95rem;
  color: #555555;
  line-height: 1.5;
  margin-bottom: 20px;
}

/* Buttons Styling */
.cookie-modal-content button {
  min-width: 100px;
  padding: 8px 15px;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Accept Button */
.cookie-modal-content .btn-outline-success {
  border-color: #28a745;
  color: #28a745;
}

.cookie-modal-content .btn-outline-success:hover {
  background-color: #28a745;
  color: #ffffff;
}

/* Reject Button */
.cookie-modal-content .btn-outline-danger {
  border-color: #dc3545;
  color: #dc3545;
}

.cookie-modal-content .btn-outline-danger:hover {
  background-color: #dc3545;
  color: #ffffff;
}

/* Add subtle responsive behavior */
@media (max-width: 480px) {
  .cookie-modal-wrapper {
    padding: 15px 20px;
  }

  .cookie-modal-content h5 {
    font-size: 1rem;
  }

  .cookie-modal-content p {
    font-size: 0.85rem;
  }

  .cookie-modal-content button {
    font-size: 0.85rem;
  }
}
.custom-btn:hover {
  background-color: var(--primary-color);
}

.map-list {
  position: absolute;
  bottom: 130px;
  left: 0;
}

.map-list li {
  color: var(--primary-color);
  font-size: 15px;
}

.custom-outline {
  color: #42DACE;
  background-color: transparent;
  padding: 13px 40px;
  font-weight: 600;
}

.custom-outline:hover {
  color: white;
  background-color: #42DACE;
}

.custom-list-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.custom-bullet {
  width: 10px;
  /* Adjust size */
  height: 10px;
  /* Adjust size */
  border-radius: 50%;
  /* Makes it circular */
  flex-shrink: 0;
  /* Ensures it maintains its size */
}

.issuance-form .form-label {
  text-transform: uppercase;
  margin-bottom: 0px;
}

.issuance-form h5 {
  text-transform: uppercase !important;
}

.issuance-form .submit {
  padding: 10px 40px;
  font-family: var(--font-family);
  background-color: var(--btn) !important;
  border: none;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.06em;
}

.issuance-form .submit:hover {
  background-color: var(--primary-color) !important;
}

.PhoneInput {
  border: 1px solid #dee2e6 !important;
  padding: 5px;
  border-radius: 5px;
}

.PhoneInputInput,
.stdropdown-input input {
  border: none !important;
}

.pointer {
  cursor: pointer !important;
}

.file-uploader:hover {
  background: var(--primary-color) !important;
  color: white !important;

}

.file-uploader:hover svg path {
  stroke: white !important;

}

.react-datepicker-wrapper {
  width: 100%;
}

.PhoneInputInput:focus-visible,
.stdropdown-input input:focus-visible {
  box-shadow: none;
  outline: none;
}

.stdropdown-input {
  padding: 0px !important;
}

/* new css ends */



.nav-link {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 15.85px;
  letter-spacing: 0.1em;
  color: var(--primary-color) !important;
}

.e-caret-none::after {
  display: none !important;
}

.offcanvas {
  width: 300px !important;
}

.active {
  color: #000000;
  font-weight: 500 !important;
}

.offcanvas .nav-link {
  border-bottom: 1px solid var(--primary-color);
}

.navbar-toggler {
  border: none;
  box-shadow: none !important;
}

.offcanvas .active {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.header-nav-link {
  position: relative;
  padding-bottom: 5px;
  transition: color 0.3s;
}

.header-nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 3px;
  background-color: var(--primary-color);
  transition: width 0.3s;
  border-radius: 20px;
}


.navbar-dropdown .dropdown-item {
  color: var(--primary-color);
  padding: 10px 15px;
  background-color: white;
  border-bottom: 1px solid var(--primary-color);
  font-weight: 300;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar-dropdown .dropdown-item:hover {
  background-color: var(--primary-color);
  color: white;
}

.navbar-dropdown.dropdown-menu {
  color: var(--primary-color);
  padding: 15px 20px !important;
  background-color: white;
  border: none !important;
  width: 250px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: box-shadow 0.3s ease;
}




.header-nav-link:hover::after {
  width: 100%;
}

.header-nav-link.active::after {
  width: 100%;
}

.hero {
  background-color: var(--background-color);
}



.hero h1 {
  font-family: var(--font-family);
  font-size: 50px;
  font-weight: 400;
  line-height: 60.76px;
  letter-spacing: 0.05em;
  color: var(--primary-color);
}

.hero p {
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 300;
  line-height: 22.52px;
  letter-spacing: 0.08em;
  color: #000000;
}

.hero button {
  padding: 10px 40px;
  font-family: var(--font-family);
  background-color: var(--primary-color) !important;
  border: none;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.06em;
}

.hero .top-text {
  color: #b49f8b;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.29px;
  letter-spacing: 0.16em;
}

.adopt-card .card-title {
  font-family: var(--font-family);
  font-size: 22px;
  font-weight: 400;
  line-height: 24.38px;
  letter-spacing: 0.09em;
  color: var(--primary-color);
}

.adopt-card .card-text {
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 400;
  line-height: 22.1px;
  letter-spacing: 0.1em;
  color: var(--gray-color);
}

.footer p {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 18.29px;
  color: var(--gray-color) !important;
}

.footer a {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 18.29px;
  color: var(--gray-color);
  text-decoration: none;
}

.contact p {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 18.29px;
  color: var(--gray-color) !important;
}

.contact a {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  line-height: 18.29px;
  color: var(--gray-color);
  text-decoration: none;
}

.heading {
  color: #a2a2a2 !important;
}

.copyright {
  color: var(--gray-color);
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 17.07px;
  letter-spacing: 0.09em;
  border: 1px solid #e7e7e7;
}

.page-heading h2 {
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 500;
  line-height: 52px;
  letter-spacing: 0.03em;
  text-align: left;
  color: var(--primary-color);
}

.line {
  width: 75px;
  height: 2px;
  background-color: #b49f8b;
  border-radius: 20px;
}



.service-card::before {
  content: "";
  display: inline-block;
  top: 0;
  width: 3.98px;
  height: 18px;
  background-color: #d9d9d9;
  margin-right: 10px;
  border: 1px solid #d9d9d9;
  margin-top: 3px;
}


.services h1 {
  font-family: var(--font-family);
  font-size: 64px;
  font-weight: 400;
  line-height: 60.76px;
  letter-spacing: 0.05em;
  color: white;
  text-transform: uppercase;
}

.services p {
  font-family: var(--font-family);
  font-size: 22px;
  font-weight: 300;
  line-height: 22.52px;
  letter-spacing: 0.08em;
  color: white;
  text-transform: uppercase;
}

.services small {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  line-height: 14.63px;
  letter-spacing: 0.09em;
  color: white;
  text-transform: uppercase;
  display: -webkit-box;
}

.separator {
  display: flex;
  align-items: center;
}

.separator .line {
  height: 1px;
  flex: 1;
  background-color: white;
}

.homeBg {
  background-image: url("../src/assets/homeBG.png");
  background-size: cover;
}

.servicebg {
  background-image: url("../src/assets/servicesbg.png");
  background-size: cover;
}

.transactionbg {
  background-image: url("../src/assets/transactionbg.png");
  background-size: cover;
}

.contactbg {
  background-image: url("../src/assets/transactionbg.png");
  background-size: cover;
}

.contact-form h1 {
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 400;
  color: var(--primary-color);
}

.contact-form p {
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 400;
  line-height: 18.29px;
  text-align: left;
  color: var(--gray-color);
}

.contact-form input {
  background-color: #ffffff;
  border: 0.5px solid var(--gray-color) !important;
  border-radius: 0 !important;
  padding: 12px;
}

.contact-form textarea {
  background-color: #ffffff;
  border: 0.5px solid var(--gray-color) !important;
  border-radius: 0 !important;
  padding: 12px;
}

.contact .form-label {
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 400;
  line-height: 18.29px;
  text-align: left;
}

.contact-form button {
  padding: 15px 40px;
  font-family: var(--font-family);
  background-color: #b49f8b !important;
  border: none;
  border-radius: 6px;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.06em;
}

.login h1 {
  font-family: var(--font-family);
  font-size: 40px;
  font-weight: 600;
  line-height: 48.76px;
  color: var(--primary-color);
}

.login .card {
  background-color: var(--background-color);
}

.login .form-label {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 15.85px;
  text-align: left;
}

.login .form-label span {
  color: red;
}

.login .form-control {
  background-color: #ffffff;
  padding: 15px 10px;
  line-height: 15.85px;
  border: none;
  border-radius: 0;
  text-align: left;
  border-bottom: 1px solid black;
}

.login .form-control:focus {
  box-shadow: none;
  border: none;
}

.login .login-btn {
  background-color: var(--primary-color);
  padding: 12px 40px;
  border-radius: 30px;
  border: none;
  font-weight: 600;
  letter-spacing: 1px;
}

.login .open-btn {
  padding: 15px;
  border: 2px solid var(--primary-color);
  border-radius: 30px;
  color: var(--primary-color);
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 500;
  line-height: 15.85px;
}

.login a {
  font-family: var(--font-family);
  font-size: 13px;
  font-weight: 400;
  line-height: 15.85px;
  text-align: center;
}

.blue-checkbox .form-check-label {
  color: var(--primary-color) !important;
  font-size: 15px;
  font-weight: 400;
  padding-left: 20px;
}

.blue-checkbox .form-check-input {
  width: 20px;
  height: 20px;
  border: 2px solid var(--primary-color);
}

.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.disclaimer .card-title {
  font-family: var(--font-family);
  font-size: 22px;
  font-weight: 500;
  line-height: 28.6px;
  letter-spacing: 0.03em;
  color: var(--primary-color);
  display: flex;
}

.disclaimer .card-title::before {
  content: "";
  display: inline-block;
  top: 0;
  width: 3.98px;
  height: 18px;
  background-color: #d9d9d9;
  margin-right: 10px;
  border: 1px solid #d9d9d9;
  margin-top: 3px;
}

.disclaimer p {
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 300;
  line-height: 25.35px;
  letter-spacing: 0.02em;
}

.disclaimer li {
  font-family: var(--font-family);
  font-size: 15px;
  font-weight: 300;
  line-height: 25.35px;
  letter-spacing: 0.02em;
}

@media (max-width:1200px) and (min-width: 1024px) {
  .vedios .display-6 {
    font-size: 30px !important;
  }

}

.map-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 85vh;
  overflow: hidden;
}



@media (max-width:1024px) {
  .map-list {
    position: static !important;
    padding: 20px;
  }

  .map-container {
    height: 100%;
  }
}



@media only screen and (max-width: 400px) {
  .issuance-form .form-label {
    font-size: 14px;
  }

  .issuance-form h5 {
    font-size: 18px;
  }
}


.composable-map {
  width: 100%;
  height: auto;
  outline: none;
}

@media only screen and (max-width: 768px) {
  .hero h1 {
    font-size: 30px;
    line-height: 48.76px;
    letter-spacing: 0.05em;
  }

  .page-heading h2 {
    font-size: 32px;
  }


  .hero p {
    font-size: 14px;
  }

  .services h1 {
    font-size: 36px;
    line-height: 40.76px;
    letter-spacing: 0.05em;
  }

  .transaction h1 {
    font-size: 36px;
    line-height: 40.76px;
    letter-spacing: 0.05em;
  }

  .services p {
    font-family: var(--font-family);
    font-size: 22px;
    font-weight: 300;
    line-height: 22.52px;
    letter-spacing: 0.08em;
    color: white;
    text-transform: uppercase;
  }
}